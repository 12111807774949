/* eslint-disable no-unused-vars */
import { of, } from 'rxjs';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';


const {
  uiActions: { progressBar, },
  roundActions: { round, },
} = bootstrapActions;

export default function handlePlaceYourBets(socketMessage) {
  // console.log('handlePlaceYourBets', { socketMessage, });

  return of(
    round.set({
      winningNumber: null, // Reset prev round's winning numbers
    }),
    progressBar.reset(),
  );
}
