import { roundReducer, socketEpic, } from './store';

const noComponent = () => Promise.resolve({ default: () => null, });

export default () => ({
  components: {
    GameHeader: noComponent,
    GameFooter: noComponent,
    GameBoard: noComponent,
    VideoOverlay: () => import('./views/VideoOverlay'),
    CallBetsToolbar: noComponent,
    BetToolbar: noComponent,
    ChipSelector: noComponent,
    GameTools: noComponent,
    GameResults: noComponent,
    ProgressBar: noComponent,
  },
  store: {
    epics: [ socketEpic, ],
    reducers: {
      round: roundReducer,
    },
  },
  retailMode: true,
  props: {
    ResultMessage: {
      winnerList: [],
    },
  },
});
