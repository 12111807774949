import { filter, flatMap, pluck, } from 'rxjs/operators';
import { combineEpics, ofType, } from 'redux-observable';

import {
  GAME_RESULT, INITIAL_DATA, PLACE_YOUR_BETS, RESULTS_OVER, WINNING_BALL,
} from '@ezugi/constants';
import { actions, } from '@ezugi/bootstrap';

import handleInitialData from './handleInitialData';
import handleGameResult from './handleGameResult';
import handleResultsOver from './handleResultsOver';
import handleWinningBall from './handleWinningBall';
import handlePlaceYourBets from './handlePlaceYourBets';

const {
  socketActions: { socket, },
} = actions;


function initialDataEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === INITIAL_DATA),
    flatMap((socketMessage) => handleInitialData(socketMessage, state$.value))
  );
}

function placeYourBetsEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === PLACE_YOUR_BETS),
    flatMap((socketMessage) => handlePlaceYourBets(socketMessage))
  );
}

function resultsOverEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === RESULTS_OVER),
    flatMap((socketMessage) => handleResultsOver(socketMessage, state$.value))
  );
}

function winningBallEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === WINNING_BALL),
    flatMap((socketMessage) => handleWinningBall(socketMessage, state$.value))
  );
}

function gameResultEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === GAME_RESULT),
    flatMap((socketMessage) => handleGameResult(socketMessage, state$.value))
  );
}

export default combineEpics(initialDataEpic, placeYourBetsEpic, gameResultEpic, resultsOverEpic, winningBallEpic);
