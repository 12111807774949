/* eslint-disable no-unused-vars */
import { of, concat, } from 'rxjs';
import { last, } from 'ramda';
import { camelize, } from 'humps';

import { mapPropNames, } from '@ezugi/utils';
import { actions, } from '@ezugi/bootstrap';
import { GAME_RESULT, } from '@ezugi/constants';

const {
  roundActions: { round, },
  gameActions: { game, },
  uiActions: { progressBar, },
} = actions;

export default function handleResultsOver(socketMessage) {
  const {
    TimerTimeLeft,
  } = socketMessage;
  // console.log('handleResultsOver', { socketMessage, });
  /*  return concat(
    of(
      round.set({
        timeLeft: TimerTimeLeft,
        totalTime: TimerTimeLeft,
      }),
    ),
    of(
      progressBar.reset(),
    )
  ); */
  return [];
}
