export const RED_BALL = 'RED_BALL';
export const WHITE_BALL = 'WHITE_BALL';

export const RED_BALLS = [
  3, 5, 6, 8, 9,
  10, 13, 15, 16, 19,
  22, 23, 26, 28,
  31, 32, 33, 35, 37, 38,
  40, 41, 43, 46, 48,
];
