/* eslint-disable radix, eqeqeq */
import { createSelector, } from 'reselect';
import { propOr, } from 'ramda';
import { selectors as bootstrapSelectors, } from '@ezugi/bootstrap';

const {
  gameSelector,
} = bootstrapSelectors;

export const gameResultsSelector = createSelector(
  gameSelector,
  propOr([], 'gameResults')
);
