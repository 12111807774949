import { RED_BALL, WHITE_BALL, } from './ballTypes';

export const BALL_COLORS = {
  [RED_BALL]: {
    color: '#EC3528',
    numberColor: '#FFFFFF',
  },
  [WHITE_BALL]: {
    color: '#FFFFFF',
    numberColor: '#000000',
  },
};
