import { of, } from 'rxjs';

import roundActions from '../../actions/round';

const { round, } = roundActions;

export default function handleWinningBall(socketMessage) {
  const { WinningBall, } = socketMessage;

  // console.log('handleWinningBall', { socketMessage, });

  return of(
    round.addWinningBall(WinningBall.BallValue),
  );
}
