import { handleActions, } from 'redux-actions';

import roundActions from '../actions/round';

const { round, } = roundActions;

const INITIAL_STATE = {};

export default handleActions(
  {
    [round.addWinningBall]: (state, { payload, }) => ({
      ...state,
      winningNumber: (state.winningNumber ?? []).concat(payload),
    }),
  },
  INITIAL_STATE
);
