import { of, } from 'rxjs';
import { LOADING, } from '@ezugi/constants';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';


import { EMPTY_ARR, } from '../../../constants';


const {
  videoActions: { video, },
  roundActions: { round, },
  gameActions: { game, },
  socketActions: { socket, },
  settingsActions: { settings, },
  uiActions: { progressBar, },
} = bootstrapActions;

export default (socketMessage) => {
  const { VideoStreamData, roundId, timeStamp, RoundTripStartTime, WinningBalls, History, } = socketMessage;

  return of(
    socket.success(),
    settings.init(),
    video.set({ streamData: VideoStreamData, }),
    round.set({
      roundStatus: LOADING,
      roundId,
      roundTime: RoundTripStartTime,
      timestamp: timeStamp,
      winningBalls: WinningBalls ?? EMPTY_ARR,
    }),
    game.set({
      gameResults: History,
    }),
    progressBar.reset(),
  );
};
