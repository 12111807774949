/* eslint-disable no-unused-vars */
import {
  compose, head, pathOr, prop, reverse, take,
} from 'ramda';
import { createSelector, } from 'reselect';
import { selectors as bootstrapSelectors, } from '@ezugi/bootstrap';
import {
  LAST_BETS, PLACE_YOUR_BETS, SHUFFLE, GAME_RESULT,
} from '@ezugi/constants';

import { gameResultsSelector, } from './game';

const { roundSelector, roundStatusSelector, } = bootstrapSelectors;

export const isBetTimeSelector = createSelector(
  roundStatusSelector,
  (roundStatus) => [ SHUFFLE, PLACE_YOUR_BETS, LAST_BETS, ].includes(roundStatus)
);

export const isGameResultSelector = createSelector(
  roundStatusSelector,
  (roundStatus) => GAME_RESULT === roundStatus
);

export const winningNumberSelector = createSelector(
  roundSelector,
  pathOr([], [ 'winningNumber', ])
);

export const allPreviousRoundsSelector = createSelector(
  gameResultsSelector,
  reverse,
);

export const previousRoundIdSelector = compose(
  prop('roundId'),
  head,
  allPreviousRoundsSelector,
);

export const previousRoundsSelector = (nbRounds) => compose(
  take(nbRounds),
  allPreviousRoundsSelector,
);
