/* eslint-disable no-unused-vars */
import { concat, of, } from 'rxjs';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import { GAME_RESULT, } from '@ezugi/constants';

import { gameResultsSelector, } from '../../selectors';
import { EMPTY_ARR, } from '../../../constants';

const {
  roundActions: { round, },
  gameActions: { game, },
  uiActions: { progressBar, },
} = bootstrapActions;


export default function handleGameResult(socketMessage, state) {
  const {
    roundId,
    WinAmount,
    GameResults,
    LastWinners,
  } = socketMessage;

  // console.log('handleGameResult', { socketMessage, });

  return concat(
    of(
      game.set({
        gameResults: gameResultsSelector(state).concat(GameResults),
        lastWin: WinAmount,
      })
    ),
    of(
      round.set({
        roundStatus: GAME_RESULT,
        roundId,
        winnerList: LastWinners,
        winningBalls: GameResults?.WinningBalls ?? EMPTY_ARR,
        winAmount: WinAmount,
      }),
      progressBar.reset(),
    )
  );
}
